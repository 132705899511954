document.addEventListener("DOMContentLoaded", function (event) {

    const init = (className, parent) => {

        let i = 0;

        const items = parent.querySelectorAll(className);

        const itemsHeight = [];

        for (i = 0; i < items.length; i++) {
            items[i].style.height = "auto";
            itemsHeight.push(items[i].offsetHeight);
        }

        const maxHeight = Math.max(...itemsHeight);

        for (i = 0; i < items.length; i++) {
            if (window.innerWidth > 768) {
                items[i].style.height = maxHeight + "px";
            } else {
                items[i].style.height = "auto";
            }
        }
    };

    const parents = document.querySelectorAll('.card-fixed-height-js');

    parents.forEach((parent) => {
        if (window.innerWidth > 768) {
            init('.c-single-card__content h3', parent);
            init('.c-single-card__content p', parent);
            init('.c-single-card__content .c-single-card__links', parent);
        }
    });

    window.addEventListener('resize', function (event) {
        parents.forEach((parent) => {
            if (window.innerWidth > 768) {
                init('.c-single-card__content h3', parent);
                init('.c-single-card__content p', parent);
                init('.c-single-card__content .c-single-card__links', parent);
            }
        });
    });

});